"use strict";


// ==========| importation de la librairie installée via npm |==========

import Typewriter from 'typewriter-effect/dist/core';

// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";


// ==========| calcul de mon age en fonction de la date |==========

// date mois 10 = novembre
let dateAnniversaire = new Date(1999, 10, 6);
let differenceDate = Date.now() - dateAnniversaire.getTime();
let age = new Date(differenceDate); 
let calculDate = Math.abs(age.getUTCFullYear() - 1970);




// ==========| afficher l'age dans les span age |==========

let ageTexte = document.querySelectorAll(".age");
ageTexte.forEach( ageElement =>{
    ageElement.innerHTML = calculDate;
});




// ==========| type wrtiter |==========

const textAnim = document.querySelector(".title--main");

if(textAnim){
    new Typewriter(textAnim, {
        loop: true
    })
    .typeString(`Mijn naam is `)
    .pauseFor(300)
    .typeString(`<span class="title__change">Arthur Haufroid</span>`)
    .pauseFor(700)
    .deleteAll()
    .typeString(`Ik ben `)
    .pauseFor(300)
    .typeString(`<span class="title__change"> ${calculDate} jaar oud</span>`)
    .pauseFor(700)
    .deleteAll()
    .pauseFor(300)
    .typeString(`Ik ben `)
    .pauseFor(300)
    .typeString(`<span class="title__change">grafisch ontwerper</span>`)
    .pauseFor(700)
    .deleteChars(18)
    .pauseFor(300)
    .typeString(`<span class="title__change">web designer</span>`)
    .pauseFor(700)
    .deleteChars(12)
    .pauseFor(300)
    .typeString(`<span class="title__change">web developper</span>`)
    .pauseFor(700)
    .deleteAll()
    .pauseFor(300)
    .start();
}




// ==========| gsap |==========

// gsap.registerPlugin(ScrollTrigger); 

// gsap.from(".header__texte", {
//     x: -2000,
//     duration: 1
// });

// gsap.from(".html__progressbar--progression", {
//     scaleX: 0,
//     scrollTrigger: {
//         trigger: ".html",
//         start: "center 75%",
//         end: "center 25%",
//         toggleActions: "play pause reverse reset",
//         scrub: true,
//         markers: true
//     }
// });

// gsap.from(".html__progressbar--emoji", {
//     x: 0,
//     scrollTrigger: {
//         trigger: ".html",
//         start: "center 75%",
//         end: "center 25%",
//         toggleActions: "play pause reverse reset",
//         scrub: true,
//         markers: true
//     }
// })

// ==========| main margin bottom |==========

let main = document.querySelector(".main");
let footer = document.querySelector(".footer");

if(main){
    main.style.marginBottom = footer.clientHeight + "px";
}




// ==========| loader | banner |==========

// let loader = document.querySelector(".loader");
let btnBannerRetour = document.querySelector(".banner__gauche--btn");
let sessionCompteurClick;

if(btnBannerRetour){

    btnBannerRetour.addEventListener("click", (e) => {
        // loader.classList.remove("loader--close");
        // loader.classList.add("loader--open");

        if(sessionStorage.getItem("compteurClick")){
            sessionCompteurClick = sessionStorage.getItem("compteurClick");
        }
        else{
            sessionCompteurClick = -1;
        }

        setTimeout(()=>{
            // window.location.href = "/";
            history.go(sessionCompteurClick);
            sessionStorage.removeItem("compteurClick");
        },200);
    });

    // window.addEventListener("load", (e) => {
    //     loader.classList.add("loader--close");
    //     loader.classList.remove("loader--open");
    // });
}
// else{
//     // window.addEventListener("load", (e) => {
//     //     if(loader.className.includes("loader--open")===true){
//     //         loader.classList.remove("loader--open");
//     //     }
//     // });

//     window.onload = () => {
//         loader.classList.remove("loader--open");
//     };
// }





// ==========| swiper |==========


let compteurClick;
let swiperBtn = document.querySelectorAll(".swiper--btn");

if(sessionStorage.getItem("compteurClick")){
    compteurClick = sessionStorage.getItem("compteurClick");
}
else{
    compteurClick = -1;
}


if(swiperBtn){
    swiperBtn.forEach(swiperElement =>{
        swiperElement.addEventListener("click" , (e) => {
            console.log("ah bah oui");
    
            compteurClick--;
    
            sessionStorage.setItem("compteurClick", compteurClick);
        });
    });
}




// ==========| menu |==========


let btnMenuOpen = document.querySelector(".menu__btn");
let menu = document.querySelector(".menu");
let container = document.querySelectorAll(".container");
let filtre = document.querySelector(".filtre");

if(btnMenuOpen){
    btnMenuOpen.addEventListener("click", (e) => {
        menu.classList.toggle("menu--open");
        btnMenuOpen.classList.toggle("menu__btn--close");
        btnMenuOpen.classList.toggle("button--close");
        btnMenuOpen.classList.toggle("button--menu");
        filtre.classList.toggle("filtre--close");
        container.forEach( containerElement => {
            containerElement.classList.toggle("container--close");
        });
        document.body.classList.toggle("body--scroll");

        let langageOpen = document.querySelectorAll(".langage--open");
        if(langageOpen){
            langageOpen.forEach(langue =>{
                closeLangage(langue);
            });
        }
        
    });
}




// ==========| liens dans le menu |==========


let btnMenuLink = document.querySelectorAll(".menu__liste--link");
btnMenuLink.forEach(MenuElement => {

    // console.log(MenuElement.children[1].innerHTML);

    MenuElement.addEventListener("click", (e) => {

        let menuLinkTexte = MenuElement.children[1].innerHTML.toLowerCase();
        menuLinkTexte = menuLinkTexte.replace("é", "e");
        
        window.location.href = "#" + menuLinkTexte;

        menu.classList.remove("menu--open");
        btnMenuOpen.classList.remove("menu__btn--close");
        filtre.classList.add("filtre--close");
        container.forEach( containerElement => {
            containerElement.classList.remove("container--close");
        });
        document.body.classList.remove("body--scroll");

        let langageOpen = document.querySelectorAll(".langage--open");
        if(langageOpen){
            langageOpen.forEach(langue =>{
                closeLangage(langue);
            });
        }
    
    });

});




// ==========| darkmode btn |==========


// let darkmodeBtn = document.querySelector(".menu__nav--btn");
let darkmodeBtn = document.querySelector(".menu__mode");
let navigation = document.querySelector(".navigation");
let theme;
// container déjà selectionné
// menu déjà selectionné
//loader déjà selectionné

let luneEmoji = document.querySelector(".btn--darkmode");
let soleilEmoji = document.querySelector(".btn--lightmode");


// page autres aussi 
let banner = document.querySelector(".banner");
let content = document.querySelector(".content");
let swiper = document.querySelector(".swiper");

if(localStorage.getItem('theme')){
    theme = localStorage.getItem('theme');
}
else{
    localStorage.setItem('theme', "light");
    theme = "light";
}

if(theme === "dark"){
    // if(menu && navigation && container && loader){
    if(menu && navigation && container){
        luneEmoji.classList.remove("hidden");
        soleilEmoji.classList.add("hidden");

        document.body.dataset.theme = "dark";
        menu.dataset.theme = "dark";
        navigation.dataset.theme = "dark";
        // loader.dataset.theme = "dark";
        container.forEach(element => {
            element.dataset.theme = "dark";
        });
    }
    // else if(banner && content && swiper&& loader){
    else if(banner && content && swiper){
        document.body.dataset.theme = "dark";
        banner.dataset.theme = "dark";
        content.dataset.theme = "dark";
        swiper.dataset.theme = "dark";
        // loader.dataset.theme = "dark";
    }
    
}


if(darkmodeBtn){
    darkmodeBtn.addEventListener("click", (e) =>{

        luneEmoji.classList.toggle("hidden");
        soleilEmoji.classList.toggle("hidden");

        container.forEach(element => {

            if(theme==="light"){
                element.dataset.theme = "dark";
            }
            else{
                element.dataset.theme = "light";
            }
        });

        if(theme==="light"){
            document.body.dataset.theme = "dark";
            menu.dataset.theme = "dark";
            navigation.dataset.theme = "dark";
            // loader.dataset.theme = "dark";
            localStorage.setItem("theme", "dark");
        }
        else{
            document.body.dataset.theme = "light";
            menu.dataset.theme = "light";
            navigation.dataset.theme = "light";
            // loader.dataset.theme = "light";
            localStorage.setItem("theme", "light");
        }

        theme = localStorage.getItem("theme");
    });
}




// ==========| emojis clicables |==========

let btnEmojis = document.querySelectorAll(".hello-emojis-list__element");
let texteEmojis = document.querySelectorAll(".hello-text-list__element");

if(btnEmojis){
    btnEmojis.forEach( emoji => {
        emoji.addEventListener("click" , (e) => {
            btnEmojis.forEach( removeClassEmoji => {
                removeClassEmoji.classList.remove("hello-emojis-list__element--selected");
                removeClassEmoji.classList.add("button--petit");
            });
            texteEmojis.forEach( removeClassEmoji => {
                removeClassEmoji.classList.remove("hello-text-list__element--selected");
            });
            let emojiId = emoji.id;
            emojiId = emojiId.substring(6);

            emoji.classList.add("hello-emojis-list__element--selected");
            emoji.classList.remove("button--petit");

            texteEmojis.forEach( texte => {
                if(texte.className.includes(emojiId)===true){
                    texte.classList.add("hello-text-list__element--selected");
                }
            })
        });
    });
}



// ==========| btn projets |==========


let btnCardProjets = document.querySelectorAll(".projets__card--btn");
btnCardProjets.forEach(btnElement => {

    // console.log(btnElement.className.substring(31));

    btnElement.addEventListener("click", (e) => {
        // loader.classList.add("loader--open");
        setTimeout(()=>{
            window.location.href = btnElement.className.substring(31) + ".html";
        },200);
        // window.location.href = btnElement.className.substring(24) + ".html";
    });
});



// ==========| btn langues |==========

let langageElement = document.querySelectorAll(".langage--element");
let langageSelected = document.querySelector(".langage--selected");
// let langageOpen = document.querySelector(".langage--open");
let drapeauFR = document.querySelector(".sitefr");
let drapeauEN = document.querySelector(".siteen");


if(langageSelected){
    langageSelected.addEventListener("click", (e) => {
        let remonte = 45;
        langageElement.forEach(langue =>{
            if(langue.className.includes("hidden")===true){
                langue.classList.remove("hidden");
                setTimeout(()=>{
                    langue.classList.add("langage--open");
                    langue.style.bottom = remonte + "px";
                    remonte = remonte + 45;
                }, 100);
            }
            else{
                closeLangage(langue);
            }
        });
    });
}

function closeLangage(langue){
    langue.classList.remove("langage--open");
    langue.style.bottom = "0";
    setTimeout(()=>{
        langue.classList.add("hidden");
    }, 100);
}


if(drapeauFR){
    drapeauFR.addEventListener("click", (e) => {
        window.location.href = "https://arthurhaufroid.be/";
    });
};

if(drapeauEN){
    drapeauEN.addEventListener("click", (e) => {
        window.location.href = "https://en.arthurhaufroid.be/";
    });
};